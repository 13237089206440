<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="allowedAction(constants.cafereoActions)"
            ><v-icon>mdi-book-search</v-icon>販売店返品一覧</template
          >
          <template v-else><v-icon>mdi-note-search</v-icon>返品一覧</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" v-if="allowedAction(constants.cafereoActions)" max-height="100%">
            <v-row dense>
              <v-col>
                <v-select
                  :items="cafereoOrderRetirnStatusList"
                  label="返品受注ステータス"
                  class="mx-2"
                  clearable
                  v-model="searchModel.orderReturnStatus"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日From"
                  dense
                  type="date"
                  v-model="searchModel.orderDateFrom"
                  :rules="[rules.orderDateFrom]"
                ></dp-date-picker
              ></v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日To"
                  dense
                  type="date"
                  v-model="searchModel.orderDateTo"
                  :rules="[rules.orderDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser || isVendorUser"
                  class="mx-2"
                  label="JANコード"
                  rows="1"
                  dense
                  v-model="searchModel.jancode"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  class="mx-2"
                  label="法人名"
                  dense
                  v-model="searchModel.corporationName"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </search-conditions>
          <search-conditions @search="onBtnSearch" v-else max-height="500px">
            <v-row dense>
              <v-col>
                <v-select
                  :items="vendorOrderRetirnStatusList"
                  label="返品受注ステータス"
                  class="mx-2"
                  clearable
                  v-model="searchModel.orderReturnStatus"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日From"
                  dense
                  type="date"
                  v-model="searchModel.createDateFrom"
                  :rules="[rules.createDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日To"
                  dense
                  type="date"
                  v-model="searchModel.createDateTo"
                  :rules="[rules.createDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser || isVendorUser"
                  class="mx-2"
                  label="JANコード"
                  rows="1"
                  dense
                  v-model="searchModel.jancode"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  class="mx-2"
                  label="法人名"
                  dense
                  v-model="searchModel.corporationName"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-menu-button
          v-if="allowedAction(constants.approvalActions)"
          text="承認・却下"
          icon="mdi-check"
          :disabled="!disabledApprovalBtns"
        >
          <v-list dense>
            <v-list-item @click="onBtnApproval(true)" dense v-if="allowedAction(constants.approvalActions)">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-check</v-icon></v-list-item-avatar>
              <v-list-item-title>承認</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnApproval(false)" dense v-if="allowedAction(constants.rejectActions)">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-close</v-icon></v-list-item-avatar>
              <v-list-item-title>却下</v-list-item-title>
            </v-list-item>
          </v-list>
        </tooltip-icon-menu-button>
        <confirm-grid-dialog ref="confirmGrid" width="1100px" height="350px" :autoClose="false"></confirm-grid-dialog>
        <tooltip-icon-button
          :disabled="!selectedRow || selectedRow.orderReturnStatus == 5"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          v-if="allowedAction(constants.updateActions) || allowedAction(constants.vendorActions)"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
          <order-return-entry
            :inputModel="orderReturnModel"
            :isReturn="true"
            @onDialogClose="onOrderDialogClose"
            @onEditSubmit="onOrderEditSubmit"
            v-if="editDialog"
          ></order-return-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="isCafereoUser && (allowedAction(constants.updateActions) || allowedAction(constants.vendorActions))"
          :disabled="disabledBulkEditBtn"
          icon="mdi-database-edit"
          @click="onBtnBulkEdit"
          >一括編集</tooltip-icon-button
        >
        <v-dialog v-model="bulkEditDialog" max-width="1800px" persistent scrollable>
          <order-return-bulk-edit
            :inputModel="selectionRows"
            @onDialogClose="onBulkEditDialogClose"
            @onEditSubmit="onBulkEditSubmit"
            v-if="bulkEditDialog"
          ></order-return-bulk-edit>
        </v-dialog>
        <tooltip-icon-button
          :disabled="!cancelFlg"
          icon="mdi-pencil-remove-outline"
          @click="onBtnCancel"
          v-if="allowedAction(constants.cancelActions)"
          >キャンセル</tooltip-icon-button
        >
        <tooltip-icon-button
          icon="mdi-book-check"
          @click="onReturnStickerPrint"
          v-if="allowedAction(constants.returnStickerActions) || allowedAction(constants.vendorActions)"
          :disabled="!(selectionRows.length > 0)"
          >返品受付書出力</tooltip-icon-button
        >
        <tooltip-icon-button
          icon="mdi-book-arrow-down"
          @click="onDeliveryReportPrint"
          v-if="allowedAction(constants.printActions) || allowedAction(constants.vendorActions)"
          :disabled="disabledDeliveryReportPrintBtn"
          >納品書出力</tooltip-icon-button
        >
        <tooltip-icon-button
          icon="mdi-download"
          @click="onBtnExport"
          v-if="allowedAction(constants.csvActions) || allowedAction(constants.vendorActions)"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 8 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                返品数合計：{{ this.returnQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                返品金額合計：￥{{ this.returnAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="OrderReturnList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="
            (data) => {
              return data.orderReturnId;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="OrderReturnInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 4"
      >
        <v-divider vertical></v-divider>
        <order-return-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :isCafereo="allowedAction(constants.cafereoActions)"
          :orderReturnId="selectedOrderReturnId"
          :orderId="selectedRow ? selectedRow.orderId : null"
        ></order-return-infos>
      </v-col>
    </v-row>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  NumericDP2Column,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import OrderReturnInfos from "./../../components/orderReturn/OrderReturnInfos.vue";
import OrderReturnEntry from "./../../components/orderReturn/OrderReturnEntry.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import CafereoOrderReturnStatus from "../../consts/orderReturnStatus/CafereoOrderReturnStatuses";
import VendorOrderReturnStatus from "../../consts/orderReturnStatus/VendorOrderReturnStatuses";
import { statuses as ApiStatus } from "../../libs/api-client";
import productConditionTypes from "../../consts/productConditionTypes";
import OrderTypes from "../../consts/OrderTypes";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import Validation from "../../libs/validation";
import OrderReturnBulkEdit from "../../components/orderReturn/OrderReturnBulkEdit.vue";

export default {
  name: "OrderReturnSearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      // 権限グループ
      constants: {
        cafereoActions: ["C020501"],
        updateActions: ["C020502", "C020508"],
        cancelActions: ["C020503"],
        approvalActions: ["C020504"],
        rejectActions: ["C020505"],
        printActions: ["C020506"],
        csvActions: ["C020507"],
        returnStickerActions: ["C020509"],
        vendorActions: ["V020401", "V020402", "V020403", "V020404", "V020405"],
      },
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        orderDateFrom: (value) => this.orderDateFromRules(value),
        orderDateTo: (value) => this.orderDateToRules(value),
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      validSeearchForm: null,
      returnQuantity: 0,
      returnAmount: 0,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpNumericDP2Column: NumericDP2Column,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      imageList: [],
      infoMaximum: false,
      cafereoOrderRetirnStatusList: CafereoOrderReturnStatus.all(),
      vendorOrderRetirnStatusList: VendorOrderReturnStatus.all(),
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      searchModel: {
        orderReturnStatus: null,
        orderDateFrom: null,
        orderDateTo: null,
        createDateFrom: null,
        createDateTo: null,
        jancode: null,
        corporationName: null,
      },
      cancelFlg: false,
      orderReturnModel: {
        createNew: false,
      },
      bulkEditModel: {},
      editDialog: false,
      bulkEditDialog: false,
      divideDialog: false,
      selectionRows: [],
      cafereoColumns: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", pinned: "left" },
        { headerName: "返品受注ID", field: "orderReturnId", pinned: "left" },
        { headerName: "返品受付番号", field: "returnReceiptNo" },
        { headerName: "伝票番号", field: "slipNo" },
        {
          headerName: "返品受注ステータス",
          field: "orderReturnStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderReturnStatus.all() },
          valueGetter: (params) => CafereoOrderReturnStatus.of(params.data.orderReturnStatus),
        },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "法人名", field: "corporationName" },
        { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
        {
          headerName: "受注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        { headerName: "卸単価", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "出荷数", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
        { headerName: "返品単価合計", field: "unitReturnPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー直送", field: "directDeliveryFlg", type: "dpCheckmarkColumn" },
        { headerName: "承認者", field: "approvalUser" },
        { headerName: "承認日時", field: "approvalDatetime", type: "dpDateColumn" },
        { headerName: "納品予約", field: "deliveryReservation", type: "dpDateColumn" },
        {
          headerName: "配送業者",
          field: "deliveryCompany",
          filter: "dpSelectionFilter",
          filterParams: { options: DeliveryCompanys.all() },
          valueGetter: (params) => DeliveryCompanys.of(params.data.deliveryCompany),
        },
        { headerName: "検品結果", field: "inspectionResult", type: "dpCheckmarkColumn" },
        { headerName: "検品数(良品)", field: "inspectionGoodQuantity", type: "dpNumericColumn" },
        { headerName: "検品数(不良品)", field: "inspectionBadQuantity", type: "dpNumericColumn" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先〒", field: "deliveryPostalCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "届先名称", field: "deliveryName2", filterParams: IncludeFilter },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn", hide: true },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      vendorColumns: [
        { headerName: "返品受注ID", field: "orderReturnId", pinned: "left" },
        { headerName: "返品受付番号", field: "returnReceiptNo" },
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "返品受注ステータス",
          field: "orderReturnStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorOrderReturnStatus.all() },
          valueGetter: (params) => VendorOrderReturnStatus.of(params.data.orderReturnStatus),
        },
        {
          headerName: "受注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        { headerName: "卸単価", field: "unitPrice", type: "dpNumericDP2Column" },
        { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
        { headerName: "返品単価合計", field: "unitReturnPriceTotal", type: "dpNumericColumn" },
        { headerName: "返品日", field: "deliveryReservation", type: "dpDateColumn" },
        {
          headerName: "配送業者",
          field: "deliveryCompany",
          filter: "dpSelectionFilter",
          filterParams: { options: DeliveryCompanys.all() },
          valueGetter: (params) => DeliveryCompanys.of(params.data.deliveryCompany),
        },
        { headerName: "配送先〒", field: "deliveryZipCode" },
        { headerName: "届先法人名", field: "delivery1" },
        { headerName: "届先名称", field: "delivery2", filterParams: IncludeFilter },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "配送先TEL", field: "deliveryPhoneNumber" },
      ],
      errorColmuns: [
        { headerName: "返品受注ID", field: "orderReturnId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      approvalColumns: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", pinned: "left" },
        { headerName: "返品受注ID", field: "orderReturnId", pinned: "left" },
        {
          headerName: "返品受注ステータス",
          field: "orderReturnStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderReturnStatus.all() },
          valueGetter: (params) => CafereoOrderReturnStatus.of(params.data.orderReturnStatus),
        },
        { headerName: "法人名", field: "corporationName" },
        { headerName: "取引先名", field: "customerName" },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionTypes.all() },
          valueGetter: (params) => productConditionTypes.of(params.data.productConditionType),
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "卸単価", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
        { headerName: "返品単価合計", field: "unitReturnPriceTotal", type: "dpNumericColumn" },
      ],
    };
  },
  components: {
    AgGridVue,
    OrderReturnInfos,
    OrderReturnEntry,
    OrderReturnBulkEdit,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    TooltipIconMenuButton,
    ConfirmGridDialog,
    ErrorGridDialog,
  },
  watch: {
    selectionRows(rows) {
      this.cancelFlg = rows.length > 0;
      rows.forEach((row) => {
        if (row.orderReturnStatus >= CafereoOrderReturnStatus.INSPECTED) this.cancelFlg = false;
      });
    },
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.onPageSizeChanged();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.columnApi.getAllColumns().map((column) => {
        let coldef = { ...column.colDef };
        if (
          coldef.field == "createDatetime" ||
          coldef.field == "updateDatetime" ||
          coldef.field == "approvalDatetime"
        ) {
          coldef.type = "dpFullDateColumn";
        }
        return coldef;
      });
      return getDisplayDetails2(this.selectedRow.orderReturnId, detailColumns, this.gridApi);
    },
    disabledApprovalBtns() {
      if (this.selectionRows.length == 0) return false;
      let statusFlg = true;
      const role = this.$store.getters["security/loggedInUser"].role;
      this.selectionRows.forEach((row) => {
        if (row.orderReturnStatus != CafereoOrderReturnStatus.NEW) {
          statusFlg = false;
        } else if (
          !(
            role == "CR:TOP" ||
            role == "CR:ST2" ||
            ((role == "CR:SS2" || role == "CR:SA2") && row.unitReturnPriceTotal < 5000000)
          )
        ) {
          statusFlg = false;
        }
      });
      return statusFlg;
    },
    selectedOrderReturnId() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.orderReturnId;
    },
    disabledBulkEditBtn() {
      if (this.selectionRows.length == 0) return true;
      let statusFlg = false;
      this.selectionRows.forEach((row) => {
        if (!statusFlg && row.orderReturnStatus == 5) {
          statusFlg = true;
        }
      });
      return statusFlg;
    },
    disabledDeliveryReportPrintBtn() {
      if (this.selectionRows.length == 0) return true;
      let statusFlg = false;
      this.selectionRows.forEach((row) => {
        if (!statusFlg && row.propositionDetailId != "") {
          statusFlg = true;
        }
      });
      return statusFlg;
    },
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = this.allowedAction(this.constants.vendorActions)
        ? "返品一覧.csv"
        : "販売店返品一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
      this.rowsSelected = this.selectionRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
    },
    onBtnUpdate() {
      this.orderReturnModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog = true;
    },
    onOrderDialogClose() {
      this.editDialog = false;
    },
    onOrderEditSubmit(updateModel) {
      this.editDialog = false;
      this.gridApi.applyTransaction({ update: [updateModel] });
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    onBtnBulkEdit() {
      this.bulkEditModel = {
        ...this.selectionRows,
      };
      this.bulkEditDialog = true;
    },
    onBulkEditDialogClose(rows) {
      this.bulkEditDialog = false;
      if (rows.length > 0) this.onBulkEditSubmit(rows);
    },
    onBulkEditSubmit(rows) {
      rows.forEach((row) => {
        this.gridApi.applyTransaction({ update: [row] });
      });
      // 連続で一括編集するときに値が変わってない対応
      this.selectionRows = this.gridApi.getSelectedRows();
      this.bulkEditDialog = false;
    },
    async onBtnCancel() {
      let messageText = `選択された返品をキャンセルします (${this.selectionRows.length}件)`;
      let title = "販売店返品一覧";
      const ok = await this.$dialog.confirm({ title: title, text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionRows.forEach((row) => {
            updateModels.push({
              orderReturnId: row.orderReturnId,
              lastUpdateDatetime: row.updateDatetime,
            });
          });
          var requestContents = {
            cancelConditions: updateModels,
          };

          const result = await this.$store.dispatch("orderReturn/cancel", requestContents);

          let error = result.data?.header;

          const updateRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    orderReturnId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              this.selectionRows.forEach((statusData) => {
                result.data.contents.orderReturns.forEach((orderReturn) => {
                  if (orderReturn.orderReturnId == statusData.orderReturnId) {
                    statusData.orderReturnStatus = orderReturn.orderReturnStatus;
                    statusData.updateDatetime = orderReturn.updateDatetime;
                    statusData.updateUser = orderReturn.updateUser;
                    updateRow.push(statusData);
                  }
                });
              });
              if (updateRow.length > 0) this.gridApi.applyTransaction({ update: updateRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "返品キャンセル", records: errorRows });
                this.$dialog.notify.error(`返品キャンセルに失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`返品がキャンセルされました (${this.selectionRows.length}件)`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderReturnSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    // 検索ボタン押下処理
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionDetailRows = [];
        var params = { ...this.searchModel };
        // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
        params.jancode = this.searchModel.jancode
          ? this.searchModel.jancode
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;

        const response = await this.$store.dispatch("orderReturn/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "返品処理";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orderReturns).length === 0) {
          this.$dialog.warning({
            title: "返品処理",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.orderReturns || []);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.returnQuantity = 0;
        this.returnAmount = 0;
        result.orderReturns.forEach((orderReturn) => {
          if (orderReturn.returnQuantity) {
            this.returnQuantity += orderReturn.returnQuantity;
          }
          if (orderReturn.unitReturnPriceTotal) {
            this.returnAmount += orderReturn.unitReturnPriceTotal;
          }
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("OrderReturnSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onfilterChanged() {
      this.returnQuantity = 0;
      this.returnAmount = 0;
      this.gridApi.forEachNode((row) => {
        if (row.displayed) {
          if (row.data.returnQuantity) {
            this.returnQuantity += row.data.returnQuantity;
          }
          if (row.data.unitReturnPriceTotal) {
            this.returnAmount += row.data.unitReturnPriceTotal;
          }
        }
      });
    },
    onPageSizeChanged() {
      if (this.gridApi) this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    async onDeliveryReportPrint() {
      try {
        this.loadingOn();
        let updateModels = [];
        let printOk = true;
        this.selectionRows.forEach((row) => {
          updateModels.push(row.orderReturnId);
          if (this.isVendorUser && row.orderReturnStatus != VendorOrderReturnStatus.INSPECTED) {
            printOk = false;
          }
        });
        if (!printOk) {
          this.$dialog.notify.error(`検品未済のものが含まれているため納品書が出力できませんでした。`, {
            timeout: 2300,
          });
          return;
        }
        var requestContents = {
          returns: updateModels,
        };
        const result = await this.$store.dispatch("orderReturn/print", requestContents);

        let error = result.data?.header;
        const errorRows = [];

        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
          case ApiStatus.consts.BUSINESS_ERROR:
            // エラーメッセージ格納
            if (error.messages) {
              Object.keys(error.messages).forEach((key) => {
                errorRows.push({
                  orderReturnId: key,
                  errorMessage: error.messages[key],
                });
              });
            }
            // 納品書をダウンロード
            if (result.data?.contents?.exportFilePath) window.open(result.data.contents.exportFilePath, "_blank");
            if (errorRows.length > 0) {
              this.$refs.bulkErrorGrid.open({ title: `納品書出力`, records: errorRows });
              this.$dialog.notify.error(`納品書出力に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            } else {
              this.$dialog.notify.info(`納品書を出力しました`, {
                timeout: 2300,
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("OrderReturnSearch::onDeliveryReportPrint", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onReturnStickerPrint() {
      try {
        this.loadingOn();
        let updateModels = [];
        this.selectionRows.forEach((row) => {
          updateModels.push(row.orderReturnId);
        });
        var requestContents = {
          returns: updateModels,
        };
        const result = await this.$store.dispatch("orderReturn/printReturn", requestContents);

        let error = result.data?.header;
        const errorRows = [];
        const updateRow = [];
        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
          case ApiStatus.consts.BUSINESS_ERROR:
            // エラーメッセージ格納
            if (error.messages) {
              Object.keys(error.messages).forEach((key) => {
                errorRows.push({
                  orderReturnId: key,
                  errorMessage: error.messages[key],
                });
              });
            }
            // 更新分のレコード変更
            this.selectionRows.forEach((row) => {
              result.data.contents.returnReceiptNoList.forEach((elm) => {
                if (row.orderReturnId == elm.orderReturnId) {
                  row.returnReceiptNo = elm.returnReceiptNo;
                  updateRow.push(row);
                }
              });
            });
            if (updateRow.length > 0) this.gridApi.applyTransaction({ update: updateRow });
            if (result.data?.contents?.exportFilePath) window.open(result.data.contents.exportFilePath, "_blank");
            if (errorRows.length > 0) {
              this.$refs.bulkErrorGrid.open({ title: `返品受付書出力`, records: errorRows });
              this.$dialog.notify.error(`返品受付書出力に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            } else {
              this.$dialog.notify.info(`返品受付書を出力しました`, {
                timeout: 2300,
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("OrderReturnSearch::onReturnStickerPrint", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    orderDateFromRules(value) {
      if (value == null || this.searchModel.orderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.orderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    orderDateToRules(value) {
      if (value == null || this.searchModel.orderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.orderDateFrom)) return "発注日Fromより後の日付を指定してください";
      return true;
    },
    createDateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.createDateTo)) return "登録日Toより前の日付を指定してください";
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.createDateFrom)) return "登録日Fromより後の日付を指定してください";
      return true;
    },
    onBtnApproval(flg) {
      const actionName = flg ? "承認" : "却下";
      this.$refs.confirmGrid.open({
        title: `販売店返品${actionName}`,
        columns: this.approvalColumns,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            records.forEach((row) => {
              const role = this.$store.getters["security/loggedInUser"].role;
              if (
                role == "CR:TOP" ||
                role == "CR:ST2" ||
                ((role == "CR:SS2" || role == "CR:SA2") && row.unitReturnPriceTotal < 5000000)
              ) {
                updateModels.push({
                  orderReturnId: row.orderReturnId,
                  updateDatetime: row.updateDatetime,
                });
              }
            });
            var requestContents = {
              orderReturns: updateModels,
            };
            let result = null;

            if (flg) {
              result = await this.$store.dispatch("orderReturn/approval", requestContents);
            } else {
              result = await this.$store.dispatch("orderReturn/reject", requestContents);
            }

            let error = result.data?.header;

            const updateRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      orderReturnId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                this.selectionRows.forEach((row) => {
                  result.data.contents.orderReturns.forEach((proposition) => {
                    if (proposition.orderReturnId == row.orderReturnId) {
                      row.orderReturnStatus = proposition.orderReturnStatus;
                      row.updateDatetime = proposition.updateDatetime;
                      row.updateUser = proposition.updateUser;
                      updateRow.push(row);
                    }
                  });
                });
                if (updateRow.length > 0) this.gridApi.applyTransaction({ update: updateRow });
                if (errorRows.length > 0) {
                  this.$refs.bulkErrorGrid.open({ title: `販売店返品${actionName}`, records: errorRows });
                  this.$dialog.notify.error(`販売店返品の${actionName}に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  let displayMessage = `販売店返品を${actionName}しました`;
                  if (updateModels.length != records.length) {
                    displayMessage += `</br>権限により500万円以上の返品は${actionName}できませんでした。`;
                  }
                  this.$dialog.notify.info(displayMessage, {
                    timeout: 2300,
                  });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("OrderReturnSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];

    if (this.allowedAction(this.constants.cafereoActions))
      this.columnDefs = this.columnDefs.concat(this.cafereoColumns);
    if (this.allowedAction(this.constants.vendorActions)) this.columnDefs = this.columnDefs.concat(this.vendorColumns);

    this.rowData = [];
  },
};
</script>
