import moment from "moment";

export const datetime = function (value, format) {
  if (value == null || String(value).trim().length === 0) {
    return "";
  }
  return moment(value).format(format || "YYYY年MM月DD日 HH時mm分ss秒");
};

export const datetimeTrimTZ = function (value, format) {
  if (value == null || String(value).trim().length === 0) {
    return "";
  }
  let replaceValue = String(value).replace(/T/g, " ").replace(/Z/g, "");
  return moment(replaceValue).format(format || "YYYY年MM月DD日 HH時mm分ss秒");
};
