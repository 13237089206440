<template>
  <order-search></order-search>
</template>

<script>
import OrderSearch from "./OrderSearch.vue";
export default {
  name: "OrderAchievementSearch",
  components: {
    OrderSearch,
  },
};
</script>
