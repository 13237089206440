<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
.cart-editing-row {
  background-color: pink !important;
}
</style>
<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="isCreation"><v-icon>mdi-pencil-plus-outline</v-icon>受注登録</span>
      <span class="headline" v-else>
        <v-icon>mdi-pencil-outline</v-icon>{{ isCafereoUser ? "受注編集" : "発注編集" }}
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="orderForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle>{{ isCafereoUser ? "受注情報" : "発注情報" }}</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense v-if="!isCreation">
            <v-col cols="12" sm="3">
              <v-text-field
                :label="isCafereoUser ? '受注ID' : '発注ID'"
                :value="updateModel.orderId"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="発注日"
                :value="updateModel.orderDate | datetime('YYYY/MM/DD')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isCafereoUser">
              <v-text-field
                label="指示書番号"
                :value="updateModel.directionNumber"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="isCafereoUser ? '受注ステータス' : '発注ステータス'"
                :value="updateModel.orderStatus | order_status_name(isCafereoUser)"
                filled
                dense
                disabled
                :error-messages="orderStatusErrorMessages"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <corporation-field
                ref="corporate"
                :rules="isCafereoUser ? [rules.required] : []"
                :group="CorporationTypeList.VENDOR"
                v-model="updateModel.corporateCode"
                :disabled="disabledCorporateCode"
                @selected="onCorporationChanged"
                v-if="isCafereoUser"
              ></corporation-field>
              <v-text-field
                label="法人名"
                :value="updateModel.corporateName"
                filled
                dense
                disabled
                v-if="isVendorUser"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <customer-field
                ref="customer"
                label="取引先名"
                v-model="updateModel.customerCode"
                :rules="isCafereoUser ? [rules.required] : []"
                :corporate="updateModel.corporateCode"
                :disabled="disabledCustomerCode"
                disabled-no-data
                @selected="onCustomerChanged"
                v-if="isCafereoUser"
              ></customer-field>
              <v-text-field
                label="取引先名"
                :value="updateModel.customerName"
                filled
                dense
                disabled
                v-if="isVendorUser"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-checkbox
                label="フォーキャスト受注"
                v-model="updateModel.forecastOrderFlg"
                :disabled="disabledForecastOrderFlg"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? '受注区分' : '発注区分'"
                :value="updateModel.orderType | order_type_name"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="!isCreation && isVendorUser">
              <v-checkbox label="カフェレオ承認済み" v-model="updateModel.approvalFlg" dense disabled></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>商品情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="JANCODE"
                v-model="updateModel.janCode"
                :rules="isCafereoUser ? [rules.required, rules.maxLength(13), rules.isNumber, rules.checkJancode] : []"
                maxlength="13"
                placeholder="45 or 49"
                filled
                dense
                :disabled="disabledJanCode"
                @input="onJanCodeChanged"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="タイトル" :value="updateModel.title" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" :value="updateModel.productName" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="productCodeModel.use && isCafereoUser">
              <v-radio-group v-model="productCodeModel.choice" row dense>
                <v-radio label="Box" :value="1"></v-radio>
                <v-radio label="Pcs" :value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="商品説明"
                :value="updateModel.productDescription"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                :label="isCafereoUser ? '受注単位' : '発注単位'"
                :value="updateModel.orderUnit | comma"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? 'カフェレオ締日' : '発注締日'"
                :value="updateModel.orderCloseDate | datetime('YYYY/MM/DD')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="発売日" :value="updateModel.releaseDate" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                :label="isCafereoUser ? '受注数' : '発注数'"
                v-model="updateModel.orderQuantity"
                :rules="[rules.required, rules.orderQuantity, rules.isNumber, rules.maxLength(8)]"
                filled
                dense
                :disabled="disabledOrderQuantity"
                @input="onQuantityChanged"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上代金額"
                :value="updateModel.retailPrice | comma"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field
                label="卸掛率"
                v-model="updateModel.wholesaleRate"
                :rules="isCafereoUser ? [rules.required, rules.isDecimal, rules.maxRateLength(3, 1)] : []"
                filled
                dense
                :disabled="disabledWholesaleRate"
                @input="onWholesaleRateChanged"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="商品単価"
                :value="updateModel.unitPrice | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(13)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上代合計"
                :value="updateModel.retailPriceTotal | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(13)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="商品単価合計"
                :value="updateModel.unitPriceTotal | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(13)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="5">
              <v-text-field label="メーカー名" :value="updateModel.supplierName" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" v-if="isCafereoUser">
              <v-text-field label="仕入掛率" :value="updateModel.purchaseRate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="仕入価格"
                :value="updateModel.purchasePrice | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(13)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="粗利"
                :value="updateModel.grossProfit | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(8)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-text-field
                label="仕入合計金額"
                :value="updateModel.lowerPriceTotal | comma"
                filled
                dense
                disabled
                :rules="[rules.maxLengthTrimComma(13)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>配送先情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="2">
              <delivery-field
                ref="delivery"
                :code="updateModel.deliveryCode"
                @selected="onDeliveryChanged"
                :groupType="2"
                :groupCode="updateModel.customerCode"
                disabled-no-data
                :rules="[rules.required]"
              ></delivery-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isCafereoUser">
              <v-switch
                label="直送依頼"
                v-model="updateModel.directDeliveryRequestFlg"
                inset
                dense
                :disabled="disabledDirectDeliveryRequestFlg"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                label="配送先〒"
                :value="updateModel.deliveryPostalCode"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="配送先住所"
                :value="updateModel.deliveryAddress"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="届先法人名" :value="updateModel.deliveryName1" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label=" 届先名称" :value="updateModel.deliveryName2" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="配送先TEL"
                :value="updateModel.deliveryTelNumber"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>付加情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="案件番号"
                :value="updateModel.propositionNumber"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="isCafereoUser">
              <v-text-field label="発注ID" :value="updateModel.purchaseId" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="isVendorUser">
            <v-col cols="12" sm="3">
              <v-text-field
                label="営業担当"
                filled
                dense
                v-model="updateModel.salesStaff"
                :disabled="isVendorUser"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="アシスタント"
                filled
                dense
                v-model="updateModel.salesAssistant"
                :disabled="isVendorUser"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="担当チーム"
                filled
                dense
                v-model="updateModel.salesTeam"
                :disabled="isVendorUser"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="備考"
                v-model="updateModel.remarks"
                :rules="isCafereoUser ? [rules.maxLength(100)] : []"
                filled
                dense
                :disabled="isVendorUser"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="isCafereoUser">
            <v-col cols="12" sm="12">
              <v-text-field
                label="社内備考"
                v-model="updateModel.cafereoRemarks"
                :rules="isCafereoUser ? [rules.maxLength(100)] : []"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="isCafereoUser">
            <v-col cols="12" sm="12">
              <v-text-field
                label="伝票備考"
                v-model="updateModel.slipRemarks"
                :rules="isCafereoUser ? [rules.maxLength(30)] : []"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="!isCreation && isCafereoUser">
            <v-col cols="12" sm="3">
              <v-text-field label="承認者" :value="updateModel.approvalUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="承認日時"
                :value="updateModel.approvalDatetime | datetime('YYYY/MM/DD')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="掛率訂正承認者"
                :value="updateModel.rateCorrectionAuthorizer"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="掛率訂正承認日時"
                :value="updateModel.rateCorrectionApprovalDate | datetimeTrimTZ('YYYY/MM/DD HH:mm:ss')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="!isCreation">
            <v-col cols="12" sm="3">
              <v-text-field
                label="販売店承認者"
                :value="updateModel.salesAuthorizer"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="販売店承認日時"
                :value="updateModel.salesApprovalDate | datetimeTrimTZ('YYYY/MM/DD HH:mm:ss')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="取引先専用番号"
                v-model="updateModel.partnerPurchaseNo"
                filled
                dense
                :rules="[rules.maxLength(64)]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="取引先専用明細番号"
                v-model="updateModel.partnerDetailNo"
                filled
                dense
                :rules="[rules.isNumber, rules.maxLength(6)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>出荷情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <dp-date-picker
                label="出荷予定日"
                v-model="updateModel.shipmentPlanDate"
                type="date"
                filled
                dense
                :disabled="isVendorUser"
              ></dp-date-picker>
            </v-col>
          </v-row>
          <v-row dense v-if="!isCreation">
            <v-col cols="12" sm="3">
              <v-text-field
                label="納品予定日"
                :value="updateModel.deliveryPlanDate | datetime('YYYY/MM/DD')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="確保倉庫"
                :value="updateModel.insureStorageName"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="!isCreation">
            <v-col cols="12" sm="3">
              <v-text-field label="伝票番号" :value="updateModel.slipNumber" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="伝票日付"
                :value="updateModel.slipDate | datetime('YYYY/MM/DD')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="isCafereoUser"></v-divider>
        <v-card-subtitle v-if="!isCreation && isCafereoUser">履歴</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="!isCreation && isCafereoUser">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="更新日時"
                :value="updateModel.updateDatetime | datetimeTrimTZ('YYYY/MM/DD HH:mm:ss')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" :value="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="登録日時"
                :value="updateModel.createDatetime | datetimeTrimTZ('YYYY/MM/DD HH:mm:ss')"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" :value="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-subtitle style="display: flex; height: 40px" v-if="enabledCart">
          <span>カート一覧</span>
          <v-spacer></v-spacer>
          <tooltip-icon-button :disabled="!selectedRow" icon="mdi-content-copy" @click="onCopyOrder"
            >コピー</tooltip-icon-button
          >
          <tooltip-icon-button :disabled="!selectedRow" icon="mdi-pencil" @click="onEditingOrder"
            >編集</tooltip-icon-button
          >
          <tooltip-icon-button :disabled="!selectedRow" icon="mdi-trash-can-outline" @click="onRemoveOrder"
            >削除</tooltip-icon-button
          >
        </v-card-subtitle>
        <v-container style="max-width: inherit" v-if="enabledCart">
          <ag-grid-vue
            id="CartList"
            class="ag-theme-alpine"
            :gridOptions="gridOptions"
            :rowData="rowData"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
          >
          </ag-grid-vue>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onAddCart" v-if="enabledCart" v-text="cartLabelAdd"></v-btn>
      <v-btn color="primary" @click="onCreateClick" v-if="isCreation">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!isCreation">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { comma } from "../../filter/NumberFilter";
import { datetime, datetimeTrimTZ } from "../../filter/DateFilter.js";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, PercentColumn, CheckmarkColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import CorporationField from "../common/fields/CorporationField.vue";
import CustomerField from "../common/fields/CustomerField.vue";
import DeliveryField from "../common/fields/DeliveryField.vue";
import CorporationTypes from "../../consts/CorporationTypes";
import OrderTypes from "../../consts/OrderTypes";
import CafereoStatus from "../../consts/orderStatus/CafereoOrderStatuses";
import VendorStatus from "../../consts/orderStatus/VendorOrderStatuses";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "OrderEntry",
  props: ["inputModel", "noCart", "modeMatter"],
  filters: {
    comma,
    datetime,
    datetimeTrimTZ,
    order_type_name: (value) => OrderTypes.of(value),
    order_status_name: (value, isCafereo) => (isCafereo ? CafereoStatus.of(value) : VendorStatus.of(value)),
  },
  components: {
    AgGridVue,
    TooltipIconButton,
    CorporationField,
    CustomerField,
    DeliveryField,
  },
  data() {
    return {
      orderTypeList: OrderTypes.all(),
      CorporationTypeList: CorporationTypes,
      updateModel: {},
      defaultModel: {
        number: null,
        rowId: null,
        orderId: null,
        orderDate: null,
        directionNumber: null,
        orderStatus: null,
        corporateCode: null,
        corporateName: null,
        customerCode: null,
        customerName: null,
        orderType: null,
        forecastOrderFlg: false,
        dedicatedOrderFlg: null,
        approvalFlg: null,
        productCode: null,
        janCode: null,
        productName: null,
        productDescription: null,
        orderUnit: null,
        orderCloseDate: null,
        releaseDate: null,
        orderQuantity: null,
        retailPrice: null,
        wholesaleRate: null,
        unitPrice: null,
        retailPriceTotal: null,
        unitPriceTotal: null,
        supplierCode: null,
        supplierName: null,
        purchaseRate: null,
        purchasePrice: null,
        grossProfit: null,
        lowerPriceTotal: null,
        deliveryCode: null,
        directDeliveryRequestFlg: null,
        deliveryPostalCode: null,
        deliveryAddress: null,
        deliveryName1: null,
        deliveryName2: null,
        deliveryTelNumber: null,
        propositionNumber: null,
        purchaseId: null,
        salesStaff: null,
        salesAssistant: null,
        salesTeam: null,
        remarks: null,
        cafereoRemarks: null,
        approvalUser: null,
        approvalDatetime: null,
        rateCorrectionAuthorizer: null,
        rateCorrectionApprovalDate: null,
        salesAuthorizer: null,
        salesApprovalDate: null,
        shipmentPlanDate: null,
        deliveryPlanDate: null,
        insureStorageCode: null,
        insureStorageName: null,
        slipNumber: null,
        slipDate: null,
        slipRemarks: null,
        updateUser: null,
        updateDatetime: null,
        createUser: null,
        createDatetime: null,
      },
      gridOptions: {
        columnTypes: {
          dpNumericColumn: { ...NumericColumn, filter: false },
          dpPercentColumn: { ...PercentColumn, filter: false },
          dpCheckmarkColumn: { ...CheckmarkColumn, filter: false },
          dpDateColumn: { ...DateColumn, filter: false },
        },
        defaultColDef: {
          filter: false,
          resizable: true,
          sortable: false,
          suppressSizeToFit: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerName: "",
            checkboxSelection: true,
            resizable: false,
          },
          {
            headerName: "",
            field: "editing",
            hide: true,
          },
        ],
        suppressCsvExport: false,
        suppressExcelExport: true,
        alwaysShowHorizontalScroll: true,
        rowSelection: "single",
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
        domLayout: "autoHeight",
        rowClassRules: {
          "cart-editing-row": (params) => Boolean(params.data.editing),
        },
      },
      rowData: [],
      selectionRows: [],
      productLoading: null,
      cartEditing: null,
      productCodeModel: {
        use: null,
        box: null,
        pcs: null,
        choice: null,
      },
      cafereoColumns: [
        { headerName: "No", field: "number" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        {
          headerName: "受注区分",
          field: "orderType",
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "フォーキャスト受注", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
        { headerName: "専用発注", field: "dedicatedOrderFlg", type: "dpCheckmarkColumn" },
        { headerName: "商品CD", field: "productCode", hide: true },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品説明", field: "productDescription" },
        { headerName: "受注単位", field: "orderUnit" },
        { headerName: "カフェレオ締日", field: "orderCloseDate", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "受注数", field: "orderQuantity", type: "dpNumericColumn" },
        { headerName: "上代金額", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "商品単価", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "上代合計", field: "retailPriceTotal", type: "dpNumericColumn" },
        { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー名", field: "supplierName" },
        { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
        { headerName: "仕入価格", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "粗利", field: "grossProfit", type: "dpNumericColumn" },
        { headerName: "下代金額合計", field: "lowerPriceTotal", type: "dpNumericColumn" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先名", field: "deliveryName" },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
        { headerName: "配送先郵便番号", field: "deliveryPostalCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "届先名称", field: "deliveryName2" },
        { headerName: "配送先電話番号", field: "deliveryTelNumber" },
        { headerName: "案件番号", field: "propositionNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        { headerName: "営業担当", field: "salesStaff" },
        { headerName: "アシスタント", field: "salesAssistant" },
        { headerName: "担当チーム", field: "salesTeam" },
        { headerName: "備考", field: "remarks" },
        { headerName: "社内備考", field: "cafereoRemarks" },
        { headerName: "伝票備考", field: "slipRemarks" },
        { headerName: "取引先専用番号", field: "partnerPurchaseNo" },
        { headerName: "取引先専用明細番号", field: "partnerDetailNo" },
      ],
      vendorColumns: [
        { headerName: "No", field: "number" },
        { headerName: "会社名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        {
          headerName: "発注区分",
          field: "orderType",
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "JANコード", field: "janCode" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品説明", field: "productDescription" },
        { headerName: "発注単位", field: "orderUnit" },
        { headerName: "発注締日", field: "orderCloseDate" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "発注数", field: "orderQuantity" },
        { headerName: "上代金額", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "掛け率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "商品単価", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "上代合計", field: "retailPriceTotal", type: "dpNumericColumn" },
        { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー名", field: "supplierName" },
        { headerName: "配送コード", field: "deliveryCode" },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
        { headerName: "配送先郵便番号", field: "deliveryPostalCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "届先名称", field: "deliveryName2" },
        { headerName: "配送先電話番号", field: "deliveryTelNumber" },
        { headerName: "案件番号", field: "propositionNumber" },
        { headerName: "営業担当", field: "salesStaff" },
        { headerName: "備考", field: "remarks" },
      ],
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDecimal: Validation.isDecimal,
        orderQuantity: (value) => this.orderQuantityRules(value),
        isRate: Validation.isRate,
        maxLengthTrimComma: Validation.maxLengthTrimComma,
        maxRateLength: Validation.maxRateLength,
        checkJancode: (value) => this.checkJancode(value),
      },
      validEntryForm: null,
      purchaseStatus: null,
      orderStatusErrorMessages: [],
      forecastFlg: false,
      salesOrderFlg: false,
      loading: false,
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    if (this.isCafereoUser) this.gridOptions.columnDefs.push(...this.cafereoColumns);
    if (this.isVendorUser) this.gridOptions.columnDefs.push(...this.vendorColumns);
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    "productCodeModel.choice"(choice) {
      this.onSwitchProduct(choice);
    },
    "updateModel.productCode"() {
      if (this.updateModel.janCode) this.$refs.orderForm.validate();
    },
    "updateModel.partnerPurchaseNo"() {
      console.log(this.updateModel.partnerPurchaseNo);
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    disabledCorporateCode() {
      return this.isVendorUser || !this.isCreation || this.modeMatter;
    },
    disabledCustomerCode() {
      return this.isVendorUser || !this.isCreation || this.modeMatter;
    },
    disabledJanCode() {
      return !this.updateModel.customerCode || !this.isCreation || this.modeMatter;
    },
    disabledOrderQuantity() {
      return (
        !this.updateModel.productCode ||
        this.modeMatter ||
        (this.isCafereoUser &&
          !this.isCreation &&
          [CafereoStatus.SECURED, CafereoStatus.SHIPPED, CafereoStatus.CANCELED].includes(this.updateModel.orderStatus))
      );
    },
    disabledForecastOrderFlg() {
      return (
        !this.forecastFlg ||
        (this.isVendorUser && this.updateModel.orderStatus && this.updateModel.orderStatus > VendorStatus.APPROVED) ||
        (this.isCafereoUser && this.updateModel.orderStatus && this.updateModel.orderStatus > CafereoStatus.NEW)
      );
    },
    disabledWholesaleRate() {
      return this.isVendorUser || !this.updateModel.productCode || this.salesOrderFlg;
    },
    enabledCart() {
      return this.isCreation && !this.noCart && !this.modeMatter;
    },
    cartLabelAdd() {
      return this.cartEditing ? "カートに反映" : "カートに追加";
    },
    isCreation() {
      return Boolean(this.inputModel.createNew);
    },
    orderUnitString() {
      return this.isCafereoUser ? "受注" : "発注";
    },
    disabledDirectDeliveryRequestFlg() {
      if (this.isCreation) return false;
      if (
        [CafereoStatus.NEW, CafereoStatus.DECLINED, CafereoStatus.APPROVED, CafereoStatus.WAITING].includes(
          this.updateModel.orderStatus
        )
      ) {
        return false;
      }
      // カフェレオ向け発注ステータスが納品予約済み未満(定数化されたら修正)
      if (this.purchaseStatus != null && this.purchaseStatus <= 6) return false;
      return true;
    },
    isLoading() {
      // return this.$store.getters["ui/isLoading"];
      return this.loading;
    },
  },
  methods: {
    init(inputModel) {
      this.initOrderModel(inputModel);
      this.resetError();
      if (this.$refs.orderForm) this.$refs.orderForm.resetValidation();
    },
    reset() {
      this.init(this.inputModel);
    },
    async initOrderModel(model) {
      if (model.createNew) {
        this.updateModel = { ...this.defaultModel, ...model };
        this.rowData = [];
        this.cartEditing = false;
        this.gridOptions.api?.deselectAll();
      } else {
        this.updateModel = { ...this.defaultModel, ...model.orderRecord };
        this.updateModel.approvalFlg = this.updateModel.approvalUser != null && this.updateModel.approvalUser != "";
        this.salesOrderFlg = OrderTypes.PURCHASE != this.updateModel.orderType && this.updateModel.unitPrice == 0;
        await this.setForcastFlg(this.updateModel.customerCode);

        if (this.isVendorUser) {
          this.updateModel.productDescription = this.updateModel.productDetail;
        } else {
          try {
            var productResponse = await this.$store.dispatch("product/search", {
              janCode: this.updateModel.janCode,
            });
            if (!ApiStatus.isSystemError(productResponse.data?.header)) {
              this.updateModel.productDescription = productResponse.data.contents.products[0].productDetail;
            }
          } catch (error) {
            console.error("OrderEntry::initOrderModel", error);
            this.apiRequestError(error);
          }
        }
        this.onQuantityChanged();
        // 紐づく発注情報のステータスを取得する
        if (this.isCafereoUser) {
          try {
            this.purchaseStatus = null;
            var searchModel = {
              searchConditions: {
                purchaseId: this.updateModel.purchaseId,
                fetchLimitOverFlg: false,
              },
            };
            const response = await this.$store.dispatch("order/searchPurchase", searchModel);
            if (!ApiStatus.isSystemError(response.data?.header)) {
              var purchaseList = response.data.contents.purchases;
              if (purchaseList.length > 0) {
                this.purchaseStatus = purchaseList[0].purchaseStatus;
              }
            }
          } catch (error) {
            console.error("OrderEntry::initOrderModel", error);
            this.apiRequestError(error);
          }
        }
      }
      this.productCodeModel = { use: null, box: null, pcs: null, choice: null };
      this.validEntryForm = null;
    },
    onGridReady(params) {
      const allColumnIds = params.columnApi.getAllColumns().map((column) => column.colId);
      params.columnApi.autoSizeColumns(allColumnIds);
    },
    onCorporationChanged(value) {
      this.updateModel.corporateCode = value;
      const corporate = this.$refs.corporate?.selected();
      this.updateModel.corporateName = corporate?.corporationName || "";
      if (this.updateModel.createNew) {
        this.searchProduct();
      }
    },
    async onCustomerChanged(value) {
      this.updateModel.customerCode = value;
      const customer = this.$refs.customer?.selected();
      this.updateModel.customerName = customer?.customerName || "";
      if (this.updateModel.createNew) {
        if (value == "" || value == null) {
          this.updateModel.forecastOrderFlg = false;
        } else {
          await this.setForcastFlg(value);
        }
        this.searchProduct();
      }
    },
    onDeliveryChanged(value) {
      this.updateModel.deliveryCode = value;
      const delivery = this.$refs.delivery?.selected();
      if (delivery) {
        this.updateModel.deliveryPostalCode = delivery.deliveryZipCode;
        this.updateModel.deliveryAddress = delivery.deliveryAddress;
        this.updateModel.deliveryName1 = delivery.delivery1;
        this.updateModel.deliveryName2 = delivery.delivery2;
        this.updateModel.deliveryTelNumber = delivery.deliveryPhoneNumber;
      } else {
        this.updateModel.deliveryPostalCode = null;
        this.updateModel.deliveryAddress = null;
        this.updateModel.deliveryName1 = null;
        this.updateModel.deliveryName2 = null;
        this.updateModel.deliveryTelNumber = null;
      }
    },
    onJanCodeChanged() {
      this.searchProduct();
    },
    onSwitchProduct(choice) {
      if (!this.productCodeModel.use) {
        return;
      }
      const product = choice === 1 ? this.productCodeModel.box : this.productCodeModel.pcs;
      this.updateModel.productCode = product.productCode;
      this.updateModel.productName = product.productName;
      this.updateModel.productDescription = product.productDescription;
      this.updateModel.orderUnit = product.orderUnit;
      this.updateModel.orderCloseDate = product.orderClosingDate;
      this.updateModel.releaseDate = product.releaseDate;
      this.updateModel.retailPrice = product.retailPrice;
      this.updateModel.wholesaleRate = product.wholesaleRate;
      this.updateModel.unitPrice = product.unitPrice;
      this.updateModel.retailPriceTotal = product.retailPriceTotal;
      this.updateModel.unitPriceTotal = product.unitPriceTotal;
      this.updateModel.supplierCode = product.supplierCode;
      this.updateModel.supplierName = product.supplierName;
      this.updateModel.purchaseRate = product.purchaseRate;
      this.updateModel.purchasePrice = product.purchasePrice;
      this.updateModel.grossProfit = product.grossProfit;
      this.updateModel.lowerPriceTotal = product.lowerPriceTotal;
      this.updateModel.title = product.title;
      this.onQuantityChanged();
    },
    onQuantityChanged() {
      this.updateModel.retailPriceTotal = this.updateModel.retailPrice * this.updateModel.orderQuantity;
      this.updateModel.unitPriceTotal = this.updateModel.unitPrice * this.updateModel.orderQuantity;
      this.updateModel.lowerPriceTotal = this.updateModel.purchasePrice * this.updateModel.orderQuantity;
      this.updateModel.grossProfit = this.updateModel.unitPriceTotal - this.updateModel.lowerPriceTotal;
    },
    onWholesaleRateChanged() {
      this.updateModel.unitPrice = Math.round(this.updateModel.retailPrice * (this.updateModel.wholesaleRate / 100));
      this.onQuantityChanged();
    },
    onAddCart() {
      this.resetError();
      if (this.validate()) {
        if (this.cartEditing) {
          const rowNumber = this.selectedRow.number;
          this.rowData[rowNumber - 1] = { ...this.updateModel };
          this.gridOptions.api.setRowData(this.rowData);
          this.$dialog.notify.info(`カートに反映しました`, { timeout: 2300 });
          this.cartEditing = false;
          this.changeEditingRowColor(false);
        } else {
          const cloneOrder = { ...this.updateModel };
          cloneOrder.number = this.rowData.length + 1;
          this.rowData.push(cloneOrder);
          this.$dialog.notify.info(`カートに追加しました`, { timeout: 2300 });
        }
        this.updateModel = { ...this.defaultModel, ...this.inputModel };
        this.$refs.orderForm.resetValidation();
        this.gridOptions.api?.deselectAll();
      }
    },
    onCopyOrder() {
      const copyOrder = { ...this.selectedRow };
      copyOrder.number = this.rowData.length + 1;
      this.rowData.push(copyOrder);
      this.$dialog.notify.info(`選択行をコピーしました`, { timeout: 2300 });
      this.updateModel = { ...this.defaultModel, ...this.inputModel };
      this.gridOptions.api?.deselectAll();
    },
    onEditingOrder() {
      this.updateModel = { ...this.selectedRow };
      this.cartEditing = true;
      this.changeEditingRowColor(true);
    },
    async onRemoveOrder() {
      const rowNumber = this.selectedRow.number;
      const ok = await this.$dialog.confirm({
        title: "受注登録",
        text: `選択行をカートから削除します (No. ${rowNumber})`,
      });
      if (ok) {
        this.rowData.splice(rowNumber - 1, 1);
        this.rowData.forEach((row, index) => {
          row.number = index + 1;
        });
        this.gridOptions.api.setRowData(this.rowData);
        this.$dialog.notify.info(`選択行をカートから削除しました (No. ${rowNumber})`, { timeout: 2300 });
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    async searchProduct() {
      this.productCodeModel.use = false;
      this.productCodeModel.box = null;
      this.productCodeModel.pcs = null;
      this.productCodeModel.choice = null;
      let product;
      if (this.updateModel.janCode?.length >= 8) {
        try {
          this.productLoading = true;
          const params = {
            customerCode: this.updateModel.customerCode,
            janCode: this.updateModel.janCode,
          };
          var response = await this.$store.dispatch("order/searchOrderAcceptedProduct", params);
          if (!ApiStatus.isSystemError(response.data?.header)) {
            product = response.data.contents?.product;
          }
        } catch (error) {
          console.error("OrderEntry::searchProduct", error);
          this.apiRequestError(error);
        } finally {
          this.productLoading = false;
        }
      }
      if (product != null) {
        this.updateModel.productCode = product.productCode;
        this.updateModel.productName = product.productName;
        this.updateModel.productDescription = product.productDescription;
        this.updateModel.orderUnit = product.orderUnit;
        this.updateModel.orderCloseDate = product.orderClosingDate;
        this.updateModel.releaseDate = product.releaseDate;
        this.updateModel.retailPrice = product.rentalPrice;
        this.updateModel.wholesaleRate = product.wholesaleRate;
        this.updateModel.unitPrice = product.unitPrice;
        this.updateModel.retailPriceTotal = product.retailPriceTotal;
        this.updateModel.unitPriceTotal = product.unitPriceTotal;
        this.updateModel.supplierCode = product.supplierCode;
        this.updateModel.supplierName = product.supplierName;
        this.updateModel.purchaseRate = product.purchaseRate;
        this.updateModel.purchasePrice = product.purchasePrice;
        this.updateModel.grossProfit = product.grossProfit;
        this.updateModel.lowerPriceTotal = product.lowerPriceTotal;
        this.updateModel.title = product.title;
        this.onQuantityChanged();
      } else {
        this.updateModel.productCode = null;
        this.updateModel.productName = null;
        this.updateModel.productDescription = null;
        this.updateModel.orderUnit = null;
        this.updateModel.orderCloseDate = null;
        this.updateModel.releaseDate = null;
        this.updateModel.retailPrice = null;
        this.updateModel.wholesaleRate = null;
        this.updateModel.unitPrice = null;
        this.updateModel.retailPriceTotal = null;
        this.updateModel.unitPriceTotal = null;
        this.updateModel.supplierCode = null;
        this.updateModel.supplierName = null;
        this.updateModel.purchaseRate = null;
        this.updateModel.purchasePrice = null;
        this.updateModel.grossProfit = null;
        this.updateModel.lowerPriceTotal = null;
        this.updateModel.title = null;
      }
    },
    async setForcastFlg(value) {
      try {
        const response = await this.$store.dispatch("customer/search", {
          customerCode: value,
        });
        let customers = response.data.contents.customers;
        if (customers.length > 0) {
          //this.updateModel.forecastOrderFlg = customers[0].forecast;
          this.forecastFlg = customers[0].forecast;
        }
      } catch (error) {
        console.error("OrderEntry::onCustomerChanged", error);
        this.apiRequestError(error);
      }
    },
    async onCreateClick() {
      if (this.rowData.length < 1) {
        this.$dialog.error({ text: "受注情報を追加してください", actions: ["OK"] });
        return;
      }
      try {
        this.loadingOnSelf();
        const orderModel = this.rowData.map(function (r) {
          return {
            customerCode: r.customerCode,
            orderType: r.orderType,
            forecastOrderFlg: Boolean(r.forecastOrderFlg),
            productCode: r.productCode,
            orderQuantity: Number(r.orderQuantity),
            wholesaleRate: Number(r.wholesaleRate),
            deliveryCode: r.deliveryCode,
            directDeliveryRequestFlg: Boolean(r.directDeliveryRequestFlg),
            shipmentPlanDate: r.shipmentPlanDate == null ? "" : r.shipmentPlanDate,
            remarks: r.remarks == null ? "" : r.remarks,
            cafereoRemarks: r.cafereoRemarks == null ? "" : r.cafereoRemarks,
            slipRemarks: r.slipRemarks == null ? "" : r.slipRemarks,
            partnerPurchaseNo: r.partnerPurchaseNo,
            partnerDetailNo: r.partnerDetailNo == "" || r.partnerDetailNo === null ? null : Number(r.partnerDetailNo),
            rowNo: Number(r.number),
          };
        });
        const response = await this.$store.dispatch("order/entry", { orders: orderModel });
        const displayName = this.isCafereoUser ? "受注" : "発注";
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            this.$dialog.notify.info(`${displayName}を登録しました (${response.data.contents.directionNumber})`);
            this.$emit("created");
            this.reset();
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            var message = this.setError(response.data?.header.messages);
            if (message != "") {
              this.$dialog.warning({
                title: `${displayName}登録`,
                text: message,
                actions: ["OK"],
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("OrderEntry::onUpdateClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOffSelf();
      }
    },
    async onUpdateClick() {
      this.resetError();
      if (this.validate()) {
        try {
          this.loadingOnSelf();
          var orderModel = null;
          console.log(this.updateModel);
          if (this.isCafereoUser) {
            orderModel = {
              orderId: this.updateModel.orderId,
              productCode: String(this.updateModel.productCode),
              orderQuantity: Number(this.updateModel.orderQuantity),
              wholesaleRate: Number(this.updateModel.wholesaleRate),
              deliveryCode: this.updateModel.deliveryCode,
              directDeliveryRequestFlg: Boolean(this.updateModel.directDeliveryRequestFlg),
              shipmentPlanDate: this.updateModel.shipmentPlanDate == null ? "" : this.updateModel.shipmentPlanDate,
              remarks: this.updateModel.remarks == null ? "" : this.updateModel.remarks,
              cafereoRemarks: this.updateModel.cafereoRemarks == null ? "" : this.updateModel.cafereoRemarks,
              slipRemarks: this.updateModel.slipRemarks == null ? "" : this.updateModel.slipRemarks,
              partnerPurchaseNo: this.updateModel.partnerPurchaseNo,
              partnerDetailNo:
                this.updateModel.partnerDetailNo == "" || this.updateModel.partnerDetailNo === null
                  ? null
                  : Number(this.updateModel.partnerDetailNo),
              lastUpdateDatetime: this.updateModel.updateDatetime,
              forecastOrderFlg: this.updateModel.forecastOrderFlg,
            };
          } else {
            orderModel = {
              orderId: this.updateModel.orderId,
              orderQuantity: Number(this.updateModel.orderQuantity),
              deliveryCode: this.updateModel.deliveryCode,
              partnerPurchaseNo: this.updateModel.partnerPurchaseNo,
              partnerDetailNo:
                this.updateModel.partnerDetailNo == "" || this.updateModel.partnerDetailNo === null
                  ? null
                  : Number(this.updateModel.partnerDetailNo),
              lastUpdateDatetime: this.updateModel.updateDatetime,
              forecastOrderFlg: this.updateModel.forecastOrderFlg,
            };
          }

          const response = await this.$store.dispatch("order/update", orderModel);
          const displayName = this.isCafereoUser ? "受注" : "発注";
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`${displayName}を更新しました (${orderModel.orderId})`, { timeout: 2300 });
              var orderRecord = response.data.contents.order;
              orderRecord.orderId = this.updateModel.orderId;
              this.$emit("updated", orderRecord);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = this.setError(response.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: `${displayName}編集`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `${displayName}編集`,
                text: response.data?.header.messages.NotItem?.join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOffSelf();
        }
      }
    },
    onCancelClick() {
      this.$emit("cancel");
      this.reset();
    },
    validate() {
      const isValid = this.$refs.orderForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    orderQuantityRules(value) {
      if (value == null) return true;
      if (Number(value) < 1) return "1以上を入力してください";
      return true;
    },
    changeEditingRowColor(flg) {
      const rowNumber = this.selectedRow.number;
      this.rowData[rowNumber - 1].editing = flg;
      this.gridOptions.api.setRowData(this.rowData);
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      if (this.isCreation) {
        var errorRows = [];
        // エラー行の行番号を取得
        Object.keys(responseMessage).forEach(function (key) {
          errorRows.push(Number(key));
        });
        // エラー行の行データのみ取得
        var newRowData = this.rowData.filter((row) => {
          return errorRows.includes(row.number);
        });
        // エラー行の行番号を初期化しエラーメッセージに初期化後の行番号を設定
        newRowData.forEach((row, index) => {
          responseMessage[row.number].forEach((Message) => {
            messages.push(index + 1 + ":" + Message);
          });
          row.number = index + 1;
        });
        this.rowData = newRowData;
        this.gridOptions.api.setRowData(this.rowData);
      } else {
        var orderStatusErrorMessages = [];
        Object.keys(responseMessage).forEach(function (key) {
          if (key == "OrderStatus") {
            orderStatusErrorMessages = responseMessage[key];
          }
          messages.push(responseMessage[key]?.join("<br>"));
        });
        this.orderStatusErrorMessages = orderStatusErrorMessages;
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
    resetError() {
      this.orderStatusErrorMessages = [];
    },
    loadingOnSelf() {
      this.loading = true;
    },
    loadingOffSelf() {
      this.loading = false;
    },
    checkJancode() {
      if (this.updateModel.productCode == null) return "JANCODEがマスタに存在しません";
      return true;
    },
  },
};
</script>
