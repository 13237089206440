<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        <v-icon>mdi-pencil-outline</v-icon>{{ allowedAction(constants.cafereoActions) ? "販売店" : "" }}返品編集
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="invoiceForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle>返品情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="返品受注ID" v-model="updateModel.orderReturnId" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="allowedAction(constants.vendorActions)">
              <v-text-field
                label="登録日"
                v-model="updateModel.createDateDisp"
                filled
                dense
                disabled
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                :items="allowedAction(constants.cafereoActions) ? orderReturnStatusList : vendorStatusList"
                label="返品受注ステータス"
                dense
                filled
                disabled
                v-model="updateModel.orderReturnStatus"
                attach
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">{{
          allowedAction(constants.cafereoActions) ? "受注情報" : "発注情報"
        }}</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="法人名" v-model="updateModel.corporationName" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="取引先名" v-model="updateModel.customerName" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>商品情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense v-if="isReturn">
            <v-col cols="12" sm="2">
              <v-select
                :disabled="!allowedAction(constants.cafereoActions)"
                :items="productConditionTypeList"
                label="商品状態区分"
                dense
                filled
                v-model="updateModel.productConditionType"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="JANCODE" v-model="updateModel.janCode" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="タイトル" v-model="updateModel.title" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" v-model="updateModel.productName" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="上代" v-model="updateModel.retailPrice" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
              <v-text-field label="卸掛率" v-model="updateModel.wholesaleRate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="卸単価" v-model="updateModel.unitPrice" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field label="出荷数" v-model="updateModel.orderQuantity" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="allowedAction(constants.vendorActions)">
              <v-text-field label="発注数" v-model="updateModel.orderQuantity" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field
                label="返品数"
                v-model="updateModel.returnQuantity"
                filled
                dense
                @change="updateModel.returnQuantity = $event !== '' ? $event : null"
                :disabled="
                  !allowedAction(constants.cafereoActions) ||
                  isQuantityDisabled ||
                  updateModel.orderReturnStatus >= inspectedStatus
                "
                :rules="[
                  rules.required,
                  rules.isNumber,
                  rules.maxLength(8),
                  rules.isMinNumber(1),
                  rules.validReturnQuantity,
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="isReturn">
              <v-text-field
                label="返品単価合計"
                v-model="updateModel.unitReturnPriceTotal"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">付加情報</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="承認者" v-model="updateModel.approvalUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="承認日時"
                v-model="updateModel.approvalDatetime"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="isReturn"></v-divider>
        <v-card-subtitle v-if="isReturn">{{
          allowedAction(constants.cafereoActions) ? "返品入荷情報" : "返品出荷情報"
        }}</v-card-subtitle>
        <v-container v-if="isReturn" style="max-width: inherit">
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="3">
              <dp-date-picker
                label="納品予約"
                v-model="updateModel.deliveryReservation"
                filled
                dense
                type="date"
                :error-messages="deliveryReservationErrorMessages"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="deliveryCompanyList"
                label="配送業者"
                dense
                filled
                v-model="updateModel.deliveryCompany"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.vendorActions)">
            <v-col cols="12" sm="3">
              <dp-date-picker
                label="返品日"
                v-model="updateModel.deliveryReservation"
                filled
                dense
                type="date"
                :rules="[rules.required, rules.isNextdayAfter, rules.validShipHourDeliveryReservation]"
                :error-messages="deliveryReservationErrorMessages"
              ></dp-date-picker>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="deliveryCompanyList"
                label="配送業者"
                dense
                filled
                v-model="updateModel.deliveryCompany"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="2">
              <v-switch
                label="検品結果"
                inset
                dense
                v-model="updateModel.inspectionResult"
                :disabled="
                  (inputModel.inspectionResult &&
                    inputModel.inspectionGoodQuantity != null &&
                    inputModel.inspectionGoodQuantity != 0) ||
                  (inputModel.inspectionResult &&
                    inputModel.inspectionBadQuantity != null &&
                    inputModel.inspectionBadQuantity != 0)
                "
                :rules="[rules.required]"
                :error-messages="inspectionResultErrorMessages"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="検品数(良品)"
                v-model="updateModel.inspectionGoodQuantity"
                filled
                dense
                :disabled="!updateModel.inspectionResult"
                @change="updateModel.inspectionGoodQuantity = $event !== '' ? $event : null"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                :error-messages="inspectionGoodQuantityErrorMessages"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="検品数(不良品)"
                v-model="updateModel.inspectionBadQuantity"
                filled
                dense
                :disabled="!updateModel.inspectionResult"
                @change="updateModel.inspectionBadQuantity = $event !== '' ? $event : null"
                :rules="[rules.isNumber, rules.maxLength(8)]"
                :error-messages="inspectionBadQuantityErrorMessages"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">履歴</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="更新日時"
                v-model="updateModel.updateDatetimeDisp"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="登録日時"
                v-model="updateModel.createDatetimeDisp"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import moment from "moment";
import Validation from "../../libs/validation";
import CafereoOrderReturnStatus from "../../consts/orderReturnStatus/CafereoOrderReturnStatuses";
import VendorOrderReturnStatus from "../../consts/orderReturnStatus/VendorOrderReturnStatuses";
import productConditionTypes from "../../consts/productConditionTypes";
import ReturnTypes from "../../consts/ReturnTypes";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import { statuses as ApiStatus } from "../../libs/api-client";
import ShipHour from "../../consts/ShipHour";

export default {
  name: "OrderReturnEntry",
  props: ["inputModel", "isReturn"],
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C020502"],
        vendorActions: ["V020402"],
      },
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isMinNumber: Validation.isMinNumber,
        isNextdayAfter: Validation.isNextdayAfter,
        validShipHourDeliveryReservation: (value) => this.validShipHourDeliveryReservation(value),
        validReturnQuantity: (value) => this.validReturnQuantity(value),
      },
      deliveryReservationErrorMessages: [],
      inspectionResultErrorMessages: [],
      inspectionGoodQuantityErrorMessages: [],
      inspectionBadQuantityErrorMessages: [],
      validEntryForm: null,
      updateModel: {},
      inspectedStatus: CafereoOrderReturnStatus.INSPECTED,
      orderReturnStatusList: CafereoOrderReturnStatus.all(),
      vendorStatusList: VendorOrderReturnStatus.all(),
      productConditionTypeList: productConditionTypes.all(false),
      deliveryCompanyList: DeliveryCompanys.all(),
    };
  },
  components: {},
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    isQuantityDisabled() {
      if (
        this.updateModel.returnType == ReturnTypes.CONSIGNMENT &&
        this.updateModel.productConditionType == productConditionTypes.GOOD
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputModel(inputModel) {
      console.log(inputModel.customerCode);
      this.initUpdateModel(inputModel);
      this.deliveryReservationErrorMessages = [];
      this.inspectionResultErrorMessages = [];
      this.inspectionGoodQuantityErrorMessages = [];
      this.inspectionBadQuantityErrorMessages = [];
    },
    "updateModel.returnQuantity"(value) {
      this.calcTotal(value);
    },
  },
  methods: {
    calcTotal(returnQuantity) {
      this.updateModel.unitReturnPriceTotal = returnQuantity * this.updateModel.unitPrice;
    },
    validReturnQuantity() {
      if (Number(this.updateModel.orderQuantity) < Number(this.updateModel.returnQuantity))
        return "返品数が出荷数を超えています。";
      return true;
    },
    initUpdateModel(inputModel) {
      // 編集の場合、引数を設定
      this.updateModel = JSON.parse(JSON.stringify(inputModel));
      this.updateModel.createDateDisp = moment(this.updateModel.createDatetime).format("YYYY-MM-DD");
      this.updateModel.createDatetimeDisp = moment(this.updateModel.createDatetime).format("YYYY/MM/DD HH:mm:ss");
      this.updateModel.updateDatetimeDisp = moment(this.updateModel.updateDatetime).format("YYYY/MM/DD HH:mm:ss");
      this.updateModel.wholesaleRate = this.updateModel.wholesaleRate.toFixed(1);
    },
    async onUpdateClick() {
      this.deliveryReservationErrorMessages = [];
      this.inspectionResultErrorMessages = [];
      this.inspectionGoodQuantityErrorMessages = [];
      this.inspectionBadQuantityErrorMessages = [];
      if (this.validate()) {
        try {
          this.loadingOn();
          const actionName = this.allowedAction(this.constants.cafereoActions) ? "販売店返品" : "返品";
          let requestContents = {
            orderReturnId: this.updateModel.orderReturnId,
            productConditionType: Number(this.updateModel.productConditionType),
            returnQuantity: Number(this.updateModel.returnQuantity),
            deliveryReservation: this.updateModel.deliveryReservation,
            deliveryCompany: this.updateModel.deliveryCompany.toString(),
            inspectionResult: this.updateModel.inspectionResult,
            inspectionGoodQuantity: this.updateModel.inspectionGoodQuantity
              ? Number(this.updateModel.inspectionGoodQuantity)
              : this.updateModel.inspectionResult
              ? 0
              : null,
            inspectionBadQuantity: this.updateModel.inspectionBadQuantity
              ? Number(this.updateModel.inspectionBadQuantity)
              : this.updateModel.inspectionResult
              ? 0
              : null,
            updateDatetime: this.updateModel.updateDatetime,
          };
          if (this.allowedAction(this.constants.vendorActions)) {
            requestContents = {
              orderReturn: {
                orderReturnId: this.updateModel.orderReturnId,
                deliveryReservation: this.updateModel.deliveryReservation,
                deliveryCompany: !this.updateModel.deliveryCompany ? null : Number(this.updateModel.deliveryCompany),
                updateDatetime: this.updateModel.updateDatetime,
              },
            };
          }
          const result = await this.$store.dispatch("orderReturn/update", requestContents);
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(
                `${actionName}を編集しました (${result.data.contents.orderReturn.orderReturnId})`,
                {
                  timeout: 2300,
                }
              );
              this.$emit("onEditSubmit", result.data.contents.orderReturn);
              this.initUpdateModel(result.data.contents.orderReturn);
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              console.log(result.data.header.messages);
              this.$dialog.warning({
                title: `${actionName}編集`,
                text: result.data.header.messages[this.updateModel.orderReturnId].join(),
                actions: ["OK"],
              });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              if (result.data.header.messages?.deliveryReservation) {
                this.deliveryReservationErrorMessages = result.data.header.messages.deliveryReservation;
              }
              if (result.data.header.messages?.inspectionResult) {
                this.inspectionResultErrorMessages = result.data.header.messages.inspectionResult;
              }
              if (result.data.header.messages?.inspectionGoodQuantity) {
                this.inspectionGoodQuantityErrorMessages = result.data.header.messages.inspectionGoodQuantity;
              }
              if (result.data.header.messages?.inspectionBadQuantity) {
                this.inspectionBadQuantityErrorMessages = result.data.header.messages.inspectionBadQuantity;
              }
              this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
              if (result.data.header.messages[this.updateModel.orderReturnId]) {
                this.$dialog.warning({
                  title: `${actionName}編集`,
                  text: result.data.header.messages[this.updateModel.orderReturnId].join(),
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validShipHourDeliveryReservation() {
      const date = new Date();
      const time = date.getHours();
      date.setDate(date.getDate() + 1);
      const reservation = new Date(this.updateModel.deliveryReservation);
      const shipHoue = ShipHour.all();
      const limitHour = this.updateModel.todayInOutFlg ? shipHoue[1].value : shipHoue[0].value;
      if (
        date.getFullYear() == reservation.getFullYear() &&
        date.getMonth() == reservation.getMonth() &&
        date.getDate() == reservation.getDate()
      ) {
        if (time > limitHour) return "納品予約が設定可能時刻を超えています";
      }
      return true;
    },
    validate() {
      const isValid = this.$refs.invoiceForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
};
</script>
